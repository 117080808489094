modules.homepage = (function () {

    var carousels = [
        {
            selector: '#rotator-cover-large .slider',
            initObject: {
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target hidden-mobile hidden-tablet"><img src="/images/seta_slider_esq.svg" /></div>',
                nextArrow: '<div class="arrow right effect-fihs-target hidden-mobile hidden-tablet"><img src="/images/seta_slider_dir.svg" /></div>',
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,				                
                speed: 500
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '#rotator-cover-large .news .frame',
            initObject: {
                mobileFirst: true,
                infinite: false,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.highlights-container',
            initObject: {
                mobileFirst: true,
                infinite: false,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target hidden-desktop"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target hidden-desktop"><span class="icon-a"></span></div>',
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            variableWidth: false,
                            arrows: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.carousel-slider',
            initObject: {
                mobileFirst: true,
                dots: true,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target hidden-mobile hidden-tablet"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target hidden-mobile hidden-tablet"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                cssEase: 'linear'
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.destaques-2 .items-container',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.destaques-4 .items-container',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target hidden-desktop"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target hidden-desktop"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        }
    ];

    function _init() {
        $(document).ready(function () {
            _initCarousels();
        });
    }

    function _initCarousels() {
        modules.main.initCarousels(carousels);
    }

    _init();

    return {};
})();
